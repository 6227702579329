import React from "react";
import { Image } from "@kards/types";

const sizeRange = {
  small: 60,
  medium: 750,
  big: 995,
};

type SmartImageProps = {
  style?: any;
  image: Image;
  size: keyof typeof sizeRange;
  alt?: string;
  hostImage: string;
  radius?: number;
  needLazy?: boolean;
};

export function SmartImage({ image, style, size, alt, hostImage, radius = 5 }: SmartImageProps) {
  const crop = `${image?.crop?.width || 0},${image?.crop?.height || 0},x${image?.crop?.x || 0},y${image?.crop?.y || 0}`;
  const src = `${hostImage}${image?.imageURL}?crop=${crop}&width=${sizeRange[size]}
  ${
    image?.photoEditorSDKOperations?.length
      ? `&${image?.photoEditorSDKOperations?.map((o) => `options[]=${JSON.stringify(o?.options)}&type[]=${o?.type}`)}`
      : ""
  }`;

  if (!image?.id)
    return (
      <img
        width="100%"
        height="100%"
        style={{ borderRadius: `${radius}px`, height: "intrinsic", ...style }}
        src={"/img/placeholderImage.png"}
        alt="placeholderImage"
      />
    );

  return (
    <img
      alt={alt}
      loading="lazy"
      decoding="async"
      style={{ borderRadius: `${radius}px`, height: "intrinsic", ...style }}
      src={src}
    />
  );
}
