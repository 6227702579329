import React, { ReactNode } from "react";
import { Box, Flex } from "reflexbox";
import { Sheet, ThemeCard } from "@kards/types";
import { Text, TitleProduct } from "../commons";
import { MediaComponent } from "../commons/Media/Media";
import { FaGlobeEurope } from "react-icons/fa";

export type SheetProps = {
  sheet: Sheet;
  theme?: ThemeCard;
  width?: string;
  hostImage: string;
  hostApi: string;
  children?: ReactNode;
};

export function Sheet({ sheet, theme, hostImage, children }: SheetProps) {
  if (!sheet) return null;

  const { reference, picture, descriptions, link } = sheet;

  return (
    <Box margin={1} className="littleBounced">
      {picture && (
        <Box style={{ position: "relative" }}>
          <MediaComponent
            hostImage={hostImage}
            value={picture}
            style={{
              width: "100%",
              height: "auto",
              borderBottomLeftRadius: "inherit",
              borderBottomRightRadius: "inherit",
            }}
          />
        </Box>
      )}
      <Box flexDirection="column" display="flex" marginTop={1}>
        <Box display="flex" flexDirection="column">
          <Flex justifyContent="space-between" width="100%">
            <TitleProduct theme={theme} title={reference} />
            {children}
          </Flex>
          {descriptions?.map((description) => (
            <Text my={"5px"} fontSize=".8em" color={theme?.color?.productDescription}>
              {description}
            </Text>
          ))}

          <Box display="flex" sx={{ overflowX: "scroll", width: "100%" }} my={"5px"}>
            {link && (
              <a href={link} target="_blank">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  color={theme?.color?.productDescription}
                  sx={{
                    width: "max-content",
                    border: "solid 1px",
                    borderColor: theme?.color?.productDescription,
                    borderRadius: "100px",
                  }}
                  p="4px"
                  mr="5px"
                >
                  <Flex fontSize="1em" px="4px">
                    <FaGlobeEurope />
                  </Flex>
                </Flex>
              </a>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
