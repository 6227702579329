import { Flex, Box } from "reflexbox";
import { TitleGreyBold, Text, Button } from "src/common-ui";
import { ContainerForm } from "./ContainerForm";
import { auth, functions } from "src/firebase";
import { Elements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "src/onboarding/CheckoutForm";
import { FormFinal } from "src/common-ui/Form";
import { useHistory } from "react-router";
import { useApp } from "src/app/AppContext";
import { AppLoader } from "src/app/AppLoader";

const stripePromise = loadStripe(window.kardsConfiguration.stripePk);

export function ErrorPaymentMethod() {
  const { establishment } = useApp();
  const [isValide, setIsValide] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [resError, setResError] = useState<boolean>(false);

  async function submit(values) {
    console.log("ici", { values });
    if (values?.payment_method) {
      try {
        setLoading(true);
        const response = await functions.httpsCallable("updateSubscriptionPayment")({
          establishment,
          ...values,
        });
        if (response?.data?.code || response?.data?.code === "internal") {
          setLoading(false);
        } else {
          history.push(`/establishments/${establishment?.id}/dashboard`);
        }
      } catch (e) {
        console.error("error", e);
        setResError(true);
      } finally {
        setLoading(false);
      }
    }
  }

  if (loading) return <AppLoader />;

  return (
    <ContainerForm loading={false}>
      <Box width={["90%", "50%"]} sx={{ zIndex: "1", overflowY: "scroll", height: "100%" }}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" mb={3} mt={2}>
          <Box p={2}>
            <img src="./img/kards/kards1.svg" alt="logo" width="300px" />
          </Box>
          <TitleGreyBold>Votre compte n'est pas à jour</TitleGreyBold>
          <Box pb={1} />
        </Flex>
        <Flex px="20px" textAlign="center" justifyContent="center" alignItems="center" width="100%">
          <Text maxWidth="800px">Il semblerait que votre abonnement ne soit plus valide.</Text>
        </Flex>
        <Flex px="20px" textAlign="center" justifyContent="center" alignItems="center" width="100%">
          <Text maxWidth="800px">
            Pour avoir accès à votre compte Kards merci de mettre à jour vos coordonnées banquaires.
          </Text>
        </Flex>

        <Flex width="100%" alignItems="center" justifyContent="center">
          <FormFinal onSubmit={submit}>
            <Elements stripe={stripePromise}>
              <CheckoutForm setIsValide={setIsValide} stripeProduct={establishment?.plan} />
            </Elements>
            <Box height="30px" width="100%">
              {resError && (
                <Text fontSize="12px" color="red.main" textAlign="center">
                  Une erreur est survenue, si le problème persiste merci de nous contacter
                </Text>
              )}
            </Box>
            <Flex p="10px" paddingBottom="30px" width="100%" alignItems="center" justifyContent="center">
              <Button disabled={!isValide} type="submit">
                Mettre à jour
              </Button>
            </Flex>
          </FormFinal>
        </Flex>

        <Box px="20px" width="100%" textAlign="center">
          <Text>Des questions ? Contactez nous sur support@kards.fr.</Text>
        </Box>
        <Flex p="10px" width="100%" alignItems="center" justifyContent="center">
          <Button onClick={() => auth.signOut()}>Se déconnecter</Button>
        </Flex>
        <Box p="40px" />
      </Box>
    </ContainerForm>
  );
}
