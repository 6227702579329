import React, { ReactNode, useState } from "react";
import { Flex, Box } from "reflexbox";
import { Text } from "../commons/Text";
import { FieldFormComponentRoot } from "@kards/types";
import styled from "styled-components";
import { Checkbox } from "theme-ui";

type FormKardProps = {
  title: string;
  color: string;
  colorText?: string;
  children: ReactNode;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

export function FormKard({ title, color, colorText, children }: FormKardProps) {
  return (
    <Box width="100%">
      <Flex
        flexDirection="column"
        width="100%"
        color={colorText || "black"}
        bg={color || "#FFF"}
        p={2}
        mt={1}
        sx={{ borderRadius: "15px" }}
      >
        <Text color="inherit" mb={2} fontWeight="bold">
          {title}
        </Text>
        <Box color={colorText || "black"}>{children}</Box>
      </Flex>
    </Box>
  );
}

export function FieldKard({ form, ...props }: { form: FieldFormComponentRoot }) {
  console.log(props);
  const [checked, setChecked] = useState<boolean>(false);

  switch (form.type) {
    case "checkbox":
      return (
        <label
          style={{
            display: "flex",
          }}
        >
          <Checkbox
            style={{
              flex: 0.1,
            }}
            {...props}
            checked={checked}
            value={checked ? "Oui" : "Non"}
            onChange={(e) => {
              setChecked(Boolean(e.target.checked));
            }}
          />
          <Text
            style={{
              flex: 0.9,
            }}
            color="inherit"
          >
            {form.label}
          </Text>
        </label>
      );

    case "comment":
      return (
        <label>
          <Text color="inherit">{form.label}</Text>
          <textarea
            style={{ backgroundColor: "white", border: "1px solid #D9D9D9", borderRadius: "5px", width: "100%" }}
            {...props}
          />
        </label>
      );

    default:
      return (
        <label>
          <Text color="inherit">{form.label}</Text>
          <Input type={form.type} style={{ width: "100%" }} {...props} />
        </label>
      );
  }
}

export function ButtonForm(props: React.ComponentProps<typeof Box> & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Box
      as="button"
      fontSize="1em"
      color="black"
      backgroundColor="white"
      px={1}
      py={1}
      sx={{ borderRadius: "5px" }}
      {...props}
    />
  );
}

export const Input = styled.input`
  border: 1px solid #d9d9d9;
  padding: 8px 14px;
  outline: none;
  font-size: 1em;
  border-radius: 4px;
  background-color: white;
  color: inherit;
  width: "100%";
  box-sizing: border-box;
  ::placeholder {
    color: "#777777";
  }
`;
